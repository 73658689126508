import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { Container, Grid } from '@material-ui/core';
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Col, Navbar, Row } from "react-bootstrap";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import { useNavigate } from "react-router-dom";

import ApiConfig from '../../config/ApiConfig';
// import { useHistory, l } from "react-router-dom";
import Axios from "axios";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { IoArrowBackCircle } from 'react-icons/io5';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // padding:"5% 5%",
    height:"auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%', // Change flexBasis to 90%
    flexShrink: 0,
  },
  icon: {
    flexBasis: '10%', // Change flexBasis to 10%
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  buttonBack: {
    // background:"yellow",
    border: "1px solid black",
    borderRadius: "50px",
    width: "100%",
    maxWidth: "8%",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "400",
    color: "black",

    '@media (max-width: 768px)': {
      fontSize: '16px',
      maxWidth: '12%',
    },

    '@media (max-width: 576px)': {
      fontSize: '14px',
      maxWidth: '15%',
    },

    '@media (max-width: 480px)': {
      fontSize: '12px',
      maxWidth: '20%',
    },
  },

}));
function FooterFaq() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  // const history = useHistory();
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleButtonClick = () => {
    navigate("/");
  };


  const HeaderSocialIconsData = [
    {
      color: "#828282",
      link: "https://www.facebook.com/InfinityAdvt/",
      icon: "fab fa-facebook-f",
    },
    {
      color: "#828282",
      link: "https://instagram.com/infinityadvt?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      icon: "fab fa-instagram",
    },
    {
      color: "#828282",
      link: "https://twitter.com/infinityadvt?lang=en",
      icon: "fab fa-twitter",
    },
    {
      color: "#828282",
      link: "https://www.youtube.com/user/infinityadvtservices",
      icon: "fab fa-youtube",
    },
    {
      color: "#828282",
      link: "https://www.linkedin.com/company/infinity-advertising-services/mycompany/",
      icon: "fab fa-linkedin",
    },
    {
      color: "#828282",
      link: " https://www.behance.net/infinityadvertising",
      icon: "fab fa-behance",
    },
  ];

  const [faqData, getFaqData] = useState([]);


  const getFaq = async () => {
    try {
      const res = await Axios.get(ApiConfig.getFaq, { 
        params: {
          page: 0,
          pageSize: 10,
        },
        Headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });

      if (res.status === 200) {
        getFaqData(res?.data?.data?.list);
      }
    }
    catch (error) {
      console.log("error message ===> ", error)
    }
  };


  useEffect(() => {
    getFaq();
  }, []);


  return (
    <Grid Container>
      <div className={classes.root}>
        <div>
          <Container style={{ display: "flex", justifyContent: "space-between", padding: "10px 0px" }}>
            <Typography variant='h3'>FAQ's</Typography>
            <button className={classes.buttonBack}
              onClick={handleButtonClick}
              to={'/'}
            ><IoArrowBackCircle /> Back
            </button>
          </Container>

          <Container>
            {faqData.map((data, index) => (
              <Accordion
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`)}
                key={index}
                style={{ padding: "10px 0px", margin: "10px 0px", background: "aliceblue", }}
              >
                <AccordionSummary
                  aria-controls={`panel${index + 1}bh-content`}
                  id={`panel${index + 1}bh-header`}
                >
                  <Typography className={classes.heading}>{data.question}</Typography>
                  <div className={classes.icon}>
                    {expanded === `panel${index + 1}` ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{data.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </div>
      </div>
    </Grid>

  );
}

export default FooterFaq;