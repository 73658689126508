import React from 'react'

function FooterContactUs() {
  return (
    <div>
      Comming Soon
    </div>
  )
}

export default FooterContactUs
