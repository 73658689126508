import React from 'react'

function FooterAboutUs() {
  return (
    <div>
      Comming soon
    </div>
  )
}

export default FooterAboutUs
