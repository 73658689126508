const HeaderData = [
    {
        title: 'Home',
        id:"home",
        link: '/home-design-agency',   
     },
    {
        title: 'About Us',
        id: 'about-us',
        link:'/footer-about-us',       
    },
    {
        title: 'Portfolio',
        id:"portfolio",
        link:"#portfolio"
    },
    {
        title: 'Services',
        id:'services',
        link:"agency"
    },
    {
        title: 'Blogs',
        id:"blogs",
        link:"agency"
    },
    // {
    //     title: 'Contact Us',
    //     id:"contactUs",
    //     link:'/footer-contact-us',      
    // }, 
]

export default HeaderData