import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';

import ReactHtmlParser from 'html-react-parser';



import ApiConfig from '../../config/ApiConfig';
import Axios from "axios";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  TermsData: {
    display: "flex", 
    gap: "10px", 
    padding: " 5px 10px", 
    background: 'linear-gradient(to bottom, #c1d5e5, #dbdfe0)', 
    color: "black", 
    margin: "10px 0%", 
    width: "100%", 
    fontSize: "20px", 
    fontWeight: "400", 
    alignItems: "center",

    "@media (max-width:500px)":{
      fontSize:"14px",
    }
  },


  mainBox:{
    padding: "30px" ,
    "@media (max-width: 600px)":{
      padding:"0px"
    }
  },
  mainBox2:{
    background: "#e9f6f8", display: "flex", padding: "20px 15px", flexDirection: "column", alignItems: "flex-start", justifyContent: "center",
    "@media (max-width: 450px)":{
      padding:"5px 10px"
    }
  },


  buttonBack: {
    border: "1px solid black",
    borderRadius: "50px",
    width: "100%",
    maxWidth: "80px",
    height: "34px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "400",
    color: "black",

    '@media (max-width: 768px)': {
      fontSize: '16px',
    },

    '@media (max-width: 576px)': {
      fontSize: '14px',
    },

    '@media (max-width: 480px)': {
      fontSize: '12px',
    },
  },

  HeaderBox:{
    display: "flex", 
    justifyContent: "space-between", 
    padding: "10px 0px", 
    width:"100%"
  },

  Header:{
    fontWeight: "800", 
    color: "black",
    "@media (max-width: 600px)":{
      fontSize:"20px",
    },
  },

  subBox:{
    width: "100%", 
    height: "auto", 
    padding: "20px 15px"
  },
})

function FooterTerm() {

  const navigate = useNavigate();
  
  const classes = useStyles();
  const [term, getTerms] = useState();

  const terms = async () => {
    try {
      const res = await Axios.get(ApiConfig.terms, {
        params: {
          page: 0,
          pageSize: 10,
        },
        Headers: {
          Authorization: window.localStorage.getItem("token"),
        },
      });

      if (res.status === 200) {
        getTerms(res?.data?.data?.content[1]);
      }
    }
    catch (error) {
      console.log("error message ===> ", error)
    }
  };


  useEffect(() => {
    terms();
  }, []);

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <div>
      <Box  className={classes.mainBox}>
        <Box className={classes.subBox}>

          <Box  className={classes.mainBox2}>


          <Box className={classes.HeaderBox} >
            
            <Typography variant='h3' style={{  }} className={classes.Header}>{term?.pageKey}</Typography>
            <button className={classes.buttonBack}
              onClick={handleButtonClick}
              to={'/'}
            ><IoArrowBackCircle /> Back
            </button>
          </Box>


            <ul style={{width:"100%"}}>
              {term?.pageData ? ReactHtmlParser(term?.pageData)
              .map((data, index) => (
                <li key={index} style={{}} className={classes.TermsData}><Brightness1Icon style={{padding:"5px"}}/> {data}</li>
              ))
                : ""}
            </ul>
          </Box>
      

        </Box>
      </Box>
    </div>
  )
}

export default FooterTerm
