// const BaseuserURL = "http://172.16.2.25:4003/account" // Local URL
const userURL="https://java-infinity.mobiloitte.io/account"// staging URL
const BaseuserURL="http://172.16.2.42:4003/account"

const ApiConfig = {

    brands: `${userURL}/all-brands`,
    // portfolio: `${userURL}/all-portfolio`,
    portfolio: `${userURL}/get-portfolio-by-categoryId`,
    blogs:`${userURL}/all-blogs`,
    // portfoliocategory:`${userURL}/all-categories-search`,
    portfoliocategory:`${userURL}/get-all-categories`,
    services:`${userURL}/services`,
    blogById:`${userURL}/get-blog-by-id`,
    portfolioById:`${userURL}/get-porfolio-by-id`,
    getFaq:`${userURL}/faq-list`,
    terms:`${userURL}/get-all-static-content`,
    contactUs:`${userURL}/conatct-us/add-new-contact-us`,
    // contactUs:`${BaseuserURL}/conatct-us/add-new-contact-us`,

};
export default ApiConfig;
